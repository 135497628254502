<template>
  <b-row>
    <b-col v-if="title != 'Tomografia de Córnea (Galilei)'">
      <h3 class="complementary-exam-title">{{ title }}</h3>
      <label for="aparelho">Aparelho</label>
      <div class="form-group mb-0 break-spaces">
        <v-text-area id="aparelho" :readonly="true" rows="1" :placeholder="item?.aparelho" />
      </div>
      <b-row>
        <b-col cols="6">
          <div class="custom-input-group">
            <div class="custom-input h-38">
              <div class="eye-area h-38">
                <EyeFill /> D
              </div>
              <div class="custom-input eye-box-container h-38 justify-center no-bb with-br">
                <span>Curvatura</span>
              </div>
              <div class="custom-input eye-box-container eye-box-container-corner h-38 justify-center no-bb">
                <span>Eixo</span>
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K1
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoK1Curvatura" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoDireitoK1Curvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoK1Eixo" type="text"
                  class="form-control" :placeholder="item?.olhoDireitoK1Eixo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K2
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoK2Curvatura" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoDireitoK2Curvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoK2Eixo" type="text"
                  class="form-control" :placeholder="item?.olhoDireitoK2Eixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Ceratometria'">
              <div class="eye-area no-br">
                Cilindro
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="ceratometria-olhoDireitoCilindroCurvatura" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoDireitoCilindroCurvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="ceratometria-olhoDireitoCilindroEixo" type="text"
                  class="form-control" :placeholder="item?.olhoDireitoCilindroEixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Ceratometria'">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb with-br no-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoKmax" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoDireitoKmax" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Topografia'">
              <div class="eye-area">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb with-br no-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoKmaxCurvatura" type="text"
                  class="form-control border-left-table no-br" :placeholder="item?.olhoDireitoKmaxCurvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoKmaxEixo" type="text"
                  class="form-control" :placeholder="item?.olhoDireitoKmaxEixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Topografia'">
              <div class="eye-area no-br">
                Cilindro
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoDireitoCilindro" type="text"
                  class="form-control" :placeholder="item?.olhoDireitoCilindro" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-bb">
                Impressão
              </div>
              <div class="custom-input no-bb border-text">
                <v-text-area id="DireitoImpressao" :readonly="true" rows="1"
                  :placeholder="item?.olhoDireito ? item?.olhoDireito : item?.DireitoImpressao"
                  class="form-control border-text" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-input-group">
            <div class="custom-input h-38">
              <div class="eye-area h-38">
                <EyeFill /> E
              </div>
              <div class="custom-input eye-box-container h-38 justify-center no-bb with-br">
                <span>Curvatura</span>
              </div>
              <div class="custom-input eye-box-container eye-box-container-corner h-38 justify-center no-bb">
                <span>Eixo</span>
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K1
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoK1Curvatura" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoEsquerdoK1Curvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoK1Eixo" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoK1Eixo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K2
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoK2Curvatura" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoEsquerdoK2Curvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoK2Eixo" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoK2Eixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Ceratometria'">
              <div class="eye-area no-br">
                Cilindro
              </div>
              <div class="custom-input no-bb with-br">
                <input autocomplete="off" :readonly="true" id="ceratometria-olhoEsquerdoCilindroCurvatura" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoCilindroCurvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="ceratometria-olhoEsquerdoCilindroEixo" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoCilindroEixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Ceratometria'">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoKmax" type="text"
                  class="form-control border-left-table" :placeholder="item?.olhoEsquerdoKmax" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Topografia'">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>

              <div class="custom-input no-bb with-br no-br">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoKmaxCurvatura" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoKmaxCurvatura" />
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoKmaxEixo" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoKmaxEixo" />
              </div>
            </div>
            <div class="custom-input no-bb" v-if="title === 'Topografia'">
              <div class="eye-area no-br">
                Cilindro
              </div>

              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="topografia-olhoEsquerdoCilindro" type="text"
                  class="form-control" :placeholder="item?.olhoEsquerdoCilindro" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-bb">
                Impressão
              </div>
              <div class="custom-input no-bb border-text">
                <v-text-area id="EsquerdoImpressao" :readonly="true" rows="1"
                  :placeholder="item?.olhoEsquerdo ? item?.olhoEsquerdo : item?.EsquerdoImpressao"
                  class="form-control border-text" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else>
      <h3>{{ title }}</h3>
      <b-row>
        <b-col cols="6">
          <div class="custom-input-group">
            <div class="custom-input h-38">
              <div class="eye-area h-38">
                <EyeFill /> D
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K médio
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-KmedioOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.KmedioOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Plano (K1)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-planoOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.planoOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Curvo (K2)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-planoOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.curvoOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Astigmatismo
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-astigmatismoOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.astigmatismoOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb with-br no-br">
                <input autocomplete="off" :readonly="true" id="tomografia-kmaxOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.kmaxOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Ponto mais fino
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kmaxOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.kmaxOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Dist. Kappa
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kappaOlhoDireito" type="text"
                  class="form-control" :placeholder="item?.kappaOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                KPI
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kpiOlhoDireito" type="text" class="form-control"
                  :placeholder="item?.kpiOlhoDireito" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area">
                Laudo
              </div>
              <div class="custom-input no-bb bbr">
                <TextArea id="laudoOlhoDireito" rows="1" autocomplete="off" :readonly="true" type="text"
                  class="form-control border-text" :placeholder="item?.laudoOlhoDireito" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-input-group">
            <div class="custom-input h-38">
              <div class="eye-area h-38">
                <EyeFill /> E
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K médio
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-KmedioOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.KmedioOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Plano (K1)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-planoOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.planoOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Curvo (K2)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-curvoOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.curvoOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Astigmatismo
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-astigmatismoOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.astigmatismoOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kmaxOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.kmaxOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Ponto mais fino
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-finoOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.finoOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Dist. Kappa
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kappaOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.kappaOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                KPI
              </div>
              <div class="custom-input no-bb">
                <input autocomplete="off" :readonly="true" id="tomografia-kpiOlhoEsquerdo" type="text"
                  class="form-control" :placeholder="item?.kpiOlhoEsquerdo" />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area">
                Laudo
              </div>
              <div class="custom-input no-bb bbr">
                <TextArea id="laudoOlhoEsquerdo" rows="1" autocomplete="off" :readonly="true" type="text"
                  class="form-control border-text" :placeholder="item?.laudoOlhoEsquerdo" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import EyeFill from '@/assets/icons/eye.svg'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    EyeFill,
    'v-text-area': TextArea,
  },

  props: {
    title: String,
    item: Object,
  },

  data() {
    return {
      open: true,
    }
  },

  methods: {
    handleOpen() {
      this.open = !this.open
    }
  },
}
</script>

<style lang="scss" scoped>
.complementary-exam-title {
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 18px;
}

.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin: 24px 0 16px 0;

  .custom-input {
    flex: 1;
    border: 8px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);

    &.justify-center {
      justify-content: center;
    }

    &.h-38 {
      height: 38px;
    }

    .form-control {
      border-radius: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      border-top: 1 solid var(--neutral-300) !important;
    }

    .padding-row {
      padding-right: 8px !important;
    }

    .border-text {
      border-bottom: 0px !important;
      border-radius: 0px 0px 10px 0px !important;
    }

    .eye-area {
      width: 140px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &.no-br {
        border-radius: 0 !important;
        border-bottom: 1px solid var(--neutral-300);
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    &.with-br {
      border-right: 1px solid var(--neutral-300);
    }

    &.bbr {
      border-radius: 0 0 8px 0 !important;
    }

    &.no-br {
      background-color: red;
      border-right: 0;
    }
  }
}

.border {
  border-right: 1px solid var(--neutral-300);
}

.eye-box-container {
  background-color: var(--neutral-100);
  align-items: center;

  .eyeValueChange {
    visibility: hidden;
  }

  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}

.eye-box-container-corner {
  border-radius: 0 8px 0 0;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--blue-500)
}

.new-topografia {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  padding-right: 30px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}

.blue {
  color: var(--blue-500);
  display: inline-block;
}
</style>